import {Button as EmotionButton} from '@rebass/emotion'
import styled from '@emotion/styled'
import BgImage from '../../assets/images/login-background-image-joanne-salon-de-Primeurs-ext-logo.jpg'

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	background-image: url(${BgImage});
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	align-items: center;
	flex-direction: column;
	font-family: 'Raleway', sans-serif;
	width: 100%;
	min-height: 100vh;
`

export const Img = styled.img`
	@media screen and (max-width: 768px) {
		margin-top: 30px;
		margin-bottom: 60px;
		max-width: 150px;
	}
	@media screen and (max-width: 768px) and (orientation: landscape) {
		margin-top: 15px;
		margin-bottom: 5px;
		width: 150px;
	}
	@media screen and (min-width: 768px) {
		margin-top: 30px;
		margin-bottom: 130px;
	}
`

export const FormContainer = styled.div`
	background-color: #fff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		margin: 10px;
		padding: 20px;
		margin-top: 20px;
	}
	@media screen and (min-width: 768px) {
		min-width: 400px;
		padding: 30px;
		margin-top: 30px;
	}
`

export const FormHeader = styled.h1`
	margin-bottom: 30px;
	margin-top: 0;
	font-weight: 700;
	color: #000;
	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
	@media screen and (min-width: 768px) {
		font-size: 30px;
	}
`

export const InputLabel = styled.h2`
	margin-bottom: 5px;
	color: #000;
	font-weight: 400;
	@media screen and (max-width: 768px) {
		font-size: 15px;
	}
	@media screen and (min-width: 768px) {
		font-size: 20px;
	}
`
export const InputTip = styled.p`
	margin-bottom: 0;
	margin-top: 0;
	color: #000;
	font-weight: 400;
	width: 100%;
	@media screen and (max-width: 768px) {
		font-size: 12px;
	}
	@media screen and (min-width: 768px) {
		font-size: 12px;
	}
`

export const Input = styled.input`
	width: -webkit-fill-available;
	height: 50px;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid #b51717;
	outline: none;
	::placeholder {
		@media screen and (max-width: 768px) {
			font-size: 15px;
		}
		@media screen and (min-width: 768px) {
			font-size: 18px;
		}
	}
`

export const Hint = styled.p`
	font-size: 12px;
	margin-top: 15px;
	font-weight: 300;
	color: #000;
`

export const FooterContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: ${props => (props.btnonly ? 'flex-end' : 'space-between')};
	margin-top: 20px;
`

export const Button = styled(EmotionButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #b51717;
	transition: background-color 0.2s ease;
	cursor: pointer;
	border: none;
	color: #fff;
	font-weight: 700;
	outline: none;
	&:hover {
		background-color: #8e1212;
	}
	@media screen and (max-width: 768px) {
		font-size: 12px;
		padding: ${props => (props.btnonly ? '10px 50px' : '10px 20px')};
	}
	@media screen and (min-width: 768px) {
		font-size: 15px;
		padding: 10px 50px;
	}
`

export const Toast = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	top: 0;
	width: 100%;
	height: 50px;
	background-color: #b51717;
	color: #fff;
`

export const ChangeFormButton = styled.button`
	background-color: transparent;
	border: none;
	color: #b51717;
	font-weight: 700;
	cursor: pointer;
	font-size: 12px;
	outline: none;
`
