import Auth from '@aws-amplify/auth'
import React, {useCallback, useEffect, useState} from 'react'

import {
	Button,
	ChangeFormButton,
	FooterContainer,
	FormHeader,
	Input,
	InputLabel,
	InputTip,
	FormContainer,
} from '../styles'

const RequiredNewPasswordForm = ({user, handleDisplayedForm, handleErrors}) => {
	const [requiredNewPassword, setRequiredNewPassword] = useState('')

	const handleRequiredNewPassword = useCallback(() => {
		Auth.completeNewPassword(user, requiredNewPassword)
			.then(() => {
				handleErrors(null)
				window.location.reload()
			})
			.catch(error => handleErrors(error.message))
	}, [requiredNewPassword, handleErrors, user])

	useEffect(() => {
		const handleUserKeyPress = e => {
			if (e.keyCode === 13) {
				handleRequiredNewPassword()
			}
		}
		window.addEventListener('keypress', handleUserKeyPress)
		return () => {
			window.removeEventListener('keypress', handleUserKeyPress)
		}
	}, [handleRequiredNewPassword])

	return (
		<FormContainer>
			<FormHeader>Change Password</FormHeader>
			<InputLabel>New Password</InputLabel>
			<InputTip>Password must be at least 8 characters long.</InputTip>
			<Input
				type='password'
				placeholder='New Password'
				onChange={e => setRequiredNewPassword(e.target.value)}
			/>
			<FooterContainer>
				<ChangeFormButton
					onClick={() => {
						handleErrors(null)
						handleDisplayedForm('signin')
					}}
				>
					Back to Sign In
				</ChangeFormButton>
				<Button onClick={handleRequiredNewPassword}>CHANGE</Button>
			</FooterContainer>
		</FormContainer>
	)
}

export default RequiredNewPasswordForm
