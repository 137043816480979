import {withAuthenticator} from 'aws-amplify-react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Amplify, {I18n} from '@aws-amplify/core'
import React from 'react'

import Home from './components/home'
import Chateaux from './components/castle/index'
import Wines from './components/wine/index'
import CastleDetails from './components/castleDetails'
import WineDetails from './components/wineDetails/'
import Header from './components/header'
import NotFound from './components/notFound'
import awsExports from './aws-exports'

import CustomAuth from './components/auth/'

Amplify.configure(awsExports)

I18n.setLanguage('en')

const App = () => {
	return (
		<Router>
			<Switch>
				<Route exact path='/'>
					<>
						<Header />
						<Home />
					</>
				</Route>
				<Route exact path='/chateaux'>
					<>
						<Header />
						<Chateaux />
					</>
				</Route>
				<Route exact path='/wines'>
					<>
						<Header />
						<Wines />
					</>
				</Route>
				<Route
					path='/chateau/:castle'
					children={
						<>
							<Header />
							<CastleDetails />
						</>
					}
				></Route>
				<Route
					path='/wine/:wine'
					children={
						<>
							<Header />
							<WineDetails />
						</>
					}
				></Route>
				<Route path='*'>
					<NotFound />
				</Route>
			</Switch>
		</Router>
	)
}

export default withAuthenticator(App, false, [<CustomAuth />], null)
