import Auth from '@aws-amplify/auth'
import React, {useCallback, useEffect, useState} from 'react'

import {
	FormContainer,
	FormHeader,
	Input,
	InputLabel,
	FooterContainer,
	ChangeFormButton,
	Button,
} from '../styles'

const ForgotPasswordForm = ({
	handleResetMail,
	handleDisplayedForm,
	handleErrors,
}) => {
	const [email, setEmail] = useState('')

	const handleForgotPassword = useCallback(() => {
		Auth.forgotPassword(email)
			.then(() => {
				handleResetMail(email)
				handleErrors(null)
				handleDisplayedForm('reset')
			})
			.catch(error => handleErrors(error.message))
	}, [email, handleDisplayedForm, handleErrors, handleResetMail])

	useEffect(() => {
		const handleUserKeyPress = e => {
			if (e.keyCode === 13) {
				handleForgotPassword()
			}
		}
		window.addEventListener('keypress', handleUserKeyPress)
		return () => {
			window.removeEventListener('keypress', handleUserKeyPress)
		}
	}, [handleForgotPassword])

	return (
		<FormContainer>
			<FormHeader>Reset your password</FormHeader>
			<InputLabel>Email</InputLabel>
			<Input
				placeholder='Enter your Email'
				onChange={e => setEmail(e.target.value)}
			/>
			<FooterContainer>
				<ChangeFormButton
					onClick={() => {
						handleErrors(null)
						handleDisplayedForm('signin')
					}}
				>
					Back to Sign In
				</ChangeFormButton>
				<Button onClick={handleForgotPassword}>SEND CODE</Button>
			</FooterContainer>
		</FormContainer>
	)
}

export default ForgotPasswordForm
