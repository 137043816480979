import {includes} from 'lodash'
import React from 'react'

import {ActionContainer, DesktopButton, MobileButton, RightIcon} from './styles'
import DownloadIcon from '../../assets/icons/download.svg'
import ViewIcon from '../../assets/icons/eye.svg'
import Analytics from '@aws-amplify/analytics'

export default (
	castleName,
	fileName,
	fileType,
	fileUrl,
	downloadUrl,
	handleOpenImage,
	handleReadVideo,
	imageFormat,
	videoFormat
) => {
	// ========================================= ANALYTICS ================================================
	const handleViewAnalytics = () => {
		Analytics.record({
			name: 'Page Chateau Action Visualiser',
			// Attribute values must be strings
			attributes: {
				chateau: castleName.castle,
				fileName: `${fileName}.${fileType}`,
			},
		})
	}
	const handleDownloadAnalytics = () => {
		Analytics.record({
			name: 'Page Chateau Action Télécharger',
			// Attribute values must be strings
			attributes: {
				chateau: castleName.castle,
				fileName: `${fileType}.${fileType}`,
			},
		})
	}
	// =========================================================================================================

	return (
		<ActionContainer>
			{/* ================ TXT FILES WITH LINKS ===================== */}
			{(fileType === 'link' || fileType === 'video') && (
				<a href={fileUrl} target='_blank' rel='noopener noreferrer'>
					<DesktopButton onClick={handleViewAnalytics}>
						View <RightIcon src={ViewIcon} />
					</DesktopButton>
					<MobileButton onClick={handleViewAnalytics}>
						<RightIcon src={ViewIcon} />
					</MobileButton>
				</a>
			)}
			{/* ==================== PDF FILES =========================== */}
			{fileType === 'pdf' && (
				<a href={fileUrl} target='_blank' rel='noopener noreferrer'>
					<DesktopButton onClick={handleViewAnalytics}>
						View <RightIcon src={ViewIcon} />
					</DesktopButton>
					<MobileButton onClick={handleViewAnalytics}>
						<RightIcon src={ViewIcon} />
					</MobileButton>
				</a>
			)}
			{/* ==================== IMAGES ======================== */}
			{includes(imageFormat, fileType) && (
				<>
					<DesktopButton
						onClick={() => {
							handleViewAnalytics()
							handleOpenImage(fileUrl)
						}}
					>
						View <RightIcon src={ViewIcon} />
					</DesktopButton>
					<MobileButton
						onClick={() => {
							handleViewAnalytics()
							handleOpenImage(fileUrl)
						}}
					>
						<RightIcon src={ViewIcon} />
					</MobileButton>
				</>
			)}
			{/* ==================== VIDEOS ======================== */}
			{includes(videoFormat, fileType) && (
				<>
					<DesktopButton
						onClick={() => {
							handleViewAnalytics()
							handleReadVideo(fileUrl)
						}}
					>
						View <RightIcon src={ViewIcon} />
					</DesktopButton>
					<MobileButton
						onClick={() => {
							handleViewAnalytics()
							handleReadVideo(fileUrl)
						}}
					>
						<RightIcon src={ViewIcon} />
					</MobileButton>
				</>
			)}
			{/* ==================== DOC FILES ================== */}
			{fileType !== 'link' && fileType !== 'video' && (
				<a href={downloadUrl} download rel='noopener noreferrer'>
					<DesktopButton onClick={handleDownloadAnalytics}>
						Download <RightIcon src={DownloadIcon} />
					</DesktopButton>
					<MobileButton onClick={handleDownloadAnalytics}>
						<RightIcon src={DownloadIcon} />
					</MobileButton>
				</a>
			)}
		</ActionContainer>
	)
}
