import {Button as EmotionButton} from '@rebass/emotion'
import styled from '@emotion/styled'
import BgImage from '../../assets/images/login-background-image-joanne-salon-de-Primeurs-ext-logo.jpg'

export const Container = styled('div')`
	display: flex;
	justify-content: flex-start;
	background-image: url(${BgImage});
	background-position: top center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	align-items: center;
	flex-direction: column;
	font-family: 'Raleway', sans-serif;
	width: 100%;
	min-height: 100vh;
	@media screen and (max-width: 768px) {
		padding: 10px;
	}
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		padding: 20px;
	}
	@media screen and (min-width: 1200px) {
		padding: 20px;
	}
`

export const Img = styled.img`
	@media screen and (max-width: 768px) {
		margin-top: 30px;
		margin-bottom: 60px;
		max-width: 150px;
	}
	@media screen and (max-width: 768px) and (orientation: landscape) {
		margin-top: 15px;
		margin-bottom: 5px;
		width: 150px;
	}
	@media screen and (min-width: 768px) {
		margin-top: 30px;
		margin-bottom: 130px;
	}
`

export const ContentContainer = styled('div')`
	background-color: #fff;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;
	border-radius: 2px;
	margin-top: 30px;
`

export const Button = styled(EmotionButton)`
	font-weight: 400;
	display: flex;
	align-items: center;
	margin-left: 15px;
	background-color: #b51717;
	padding-top: 5px;
	padding-bottom: 5px;
	transition: background-color 0.2s ease;
	outline: none;
	height: 50px;
	margin-top: 30px;
	cursor: pointer;
	:hover {
		background-color: #8e1212;
	}
`

export const Title = styled.h1`
	color: #2b2b2b !important;
	font-size: 50px;
	margin: 0px;
	margin-bottom: 15px;
`

export const Subtitle = styled.h2`
	color: #2b2b2b !important;
	font-size: 30px;
	margin: 0px;
`
