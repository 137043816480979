import Auth from '@aws-amplify/auth'
import React, {useCallback, useEffect, useState} from 'react'

import {
	Button,
	ChangeFormButton,
	FooterContainer,
	FormHeader,
	Input,
	InputLabel,
	InputTip,
	FormContainer,
} from '../styles'

const ResetPasswordForm = ({resetEmail, handleDisplayedForm, handleErrors}) => {
	const [newPassword, setNewPassword] = useState('')
	const [forgotCode, setForgotCode] = useState('')

	const handleResetPassword = useCallback(() => {
		Auth.forgotPasswordSubmit(resetEmail, forgotCode, newPassword)
			.then(() => {
				handleErrors(null)
				handleDisplayedForm('signin')
			})
			.catch(error => {
				handleDisplayedForm('reset')
				handleErrors(error.message)
			})
	}, [forgotCode, newPassword, handleDisplayedForm, handleErrors, resetEmail])

	const handleForgotPassword = () => {
		Auth.forgotPassword(resetEmail)
			.then(handleDisplayedForm('reset'))
			.catch(error => handleErrors(error.message))
	}

	useEffect(() => {
		const handleUserKeyPress = e => {
			if (e.keyCode === 13) {
				handleResetPassword()
			}
		}
		window.addEventListener('keypress', handleUserKeyPress)
		return () => {
			window.removeEventListener('keypress', handleUserKeyPress)
		}
	}, [handleResetPassword])

	return (
		<FormContainer>
			<FormHeader>Reset your password</FormHeader>
			<InputLabel>Code</InputLabel>
			<Input
				placeholder='Code'
				onChange={e => setForgotCode(e.target.value)}
			/>
			<InputLabel>New Password</InputLabel>
			<InputTip>Password must be at least 8 characters long.</InputTip>
			<Input
				type='password'
				placeholder='New Password'
				onChange={e => setNewPassword(e.target.value)}
			/>
			<FooterContainer>
				<ChangeFormButton onClick={handleForgotPassword}>
					Resend code
				</ChangeFormButton>
				<Button onClick={handleResetPassword}>SUBMIT</Button>
			</FooterContainer>
		</FormContainer>
	)
}

export default ResetPasswordForm
