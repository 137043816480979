import {Button as EmotionButton} from '@rebass/emotion'
import styled from '@emotion/styled'

export const Container = styled.div`
	min-height: 100vh;
	background-color: #fff;
	display: flex;
	justify-content: center;
	@media screen and (max-width: 768px) {
		padding: 0 10px 10px 10px;
	}
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		padding: 0 20px 20px 20px;
	}
	@media screen and (min-width: 1200px) {
		padding: 0 20px 20px 20px;
	}
`

export const Content = styled.div`
	width: 100%;
	max-width: 800px;
`

export const HeaderSection = styled('div')`
	font-family: 'Raleway', sans-serif;
	width: 100%;
	margin-bottom: 50px;
`

export const HeaderTitle = styled.h2`
	color: #000;
	font-weight: 700;
	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		font-size: 30px;
	}
	@media screen and (min-width: 1200px) {
		font-size: 30px;
	}
`

export const Separator = styled.div`
	background-color: #b51717;
	height: 2px;
	width: 100%;
	margin-bottom: 30px;
`

export const BannerImage = styled.img`
	width: 100%;
	margin-top: 20px;
`
export const BannerDiv = styled.div`
	background-image: url(${props => props.bgImg});
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: inherit;
	background-size: cover;
	width: 100%;
	height: 250px;
	margin-top: 20px;
`

export const DocDiv = styled.div`
	max-width: 100%;
	background-color: #f8f8f8;
	color: #000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	padding: 0 20px;
`

export const DocTitleSection = styled.div`
	display: flex;
	align-items: center;
`

export const LeftIcon = styled.img`
	width: 25px;
	margin-right: 10px;
`

export const DocName = styled.p``

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
`

export const Button = styled(EmotionButton)`
	font-weight: 400;
	display: flex;
	align-items: center;
	margin-left: 15px;
	background-color: #b51717;
	max-height: 30px;
	padding-top: 5px;
	padding-bottom: 5px;
	transition: background-color 0.2s ease;
	outline: none;
	cursor: pointer;
	:hover {
		background-color: #8e1212;
	}
	@media screen and (max-width: 768px) {
		padding-left: 10px;
		padding-right: 10px;
	}
	@media screen and (min-width: 768px) {
		padding-left: 16px;
		padding-right: 16px;
	}
`

export const MobileButton = styled(Button)`
	@media screen and (max-width: 768px) {
		display: flex;
	}
	@media screen and (min-width: 768px) {
		display: none;
	}
`
export const DesktopButton = styled(Button)`
	@media screen and (max-width: 768px) {
		display: none;
	}
	@media screen and (min-width: 768px) {
		display: flex;
	}
`

export const RightIcon = styled.img`
	width: 20px;
	@media screen and (max-width: 768px) {
		margin-left: 0px;
	}
	@media screen and (min-width: 768px) {
		margin-left: 10px;
	}
`

export const Icon = styled.img`
	width: 20px;
	margin-right: 10px;
`

export const Img = styled.img`
	max-width: 90%;
	max-height: 70%;
`

export const BackBtn = styled.button`
	background-color: #f8f8f8;
	border: none;
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 20px;
	padding: 10px 20px;
	margin-top: 20px;
	cursor: pointer;
	outline: none;
	transition: background-color 0.2s ease;
	:hover {
		background-color: #f2f2f2;
	}
`

export const ViewerContainer = styled.div`
	width: 100%;
	height: 100vh;
	position: absolute;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
`

export const Video = styled.video`
	outline: none;
	@media screen and (max-width: 768px) {
		max-width: 90%;
	}
	@media screen and (min-width: 768px) {
		max-width: 70%;
	}
`

export const Close = styled.img`
	width: 20px;
	position: absolute;
	z-index: 2;
	top: 20px;
	right: 20px;
	cursor: pointer;
`
