import Auth from '@aws-amplify/auth'
import React from 'react'
import {Link} from 'react-router-dom'

import {
	Container,
	DesktopSignOutButton,
	Img,
	Logo,
	MobileSignOutButton,
	Title,
} from './styles'
import JoanneLogo from '../../assets/images/logo-joanne.png'
import Logout from '../../assets/icons/logout.svg'

const Header = () => {
	return (
		<Container>
			<Link to='/'>
				<Logo src={JoanneLogo} />
			</Link>
			<Title>Releases Portal</Title>
			<DesktopSignOutButton
				onClick={() => {
					Auth.signOut().then(() => window.location.reload())
				}}
			>
				<Img src={Logout} /> Logout
			</DesktopSignOutButton>
			<MobileSignOutButton
				onClick={() => {
					Auth.signOut().then(() => window.location.reload())
				}}
			>
				<Img src={Logout} />
			</MobileSignOutButton>
		</Container>
	)
}

export default Header
