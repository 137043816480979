import {Button} from '@rebass/emotion'
import styled from '@emotion/styled'

export const Container = styled.div`
	width: -webkit-fill-available;
	background-color: #000;

	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	@media screen and (max-width: 768px) {
		padding-left: 10px;
		padding-right: 10px;
	}
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		padding-left: 30px;
		padding-right: 30px;
	}
	@media screen and (min-width: 1200px) {
		padding-left: 30px;
		padding-right: 30px;
	}
`

export const Logo = styled.img`
	@media screen and (max-width: 768px) {
		width: 60px;
	}
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		width: 80px;
	}
	@media screen and (min-width: 1200px) {
		width: 100px;
	}
`

export const Title = styled.h1`
	color: #fff;
	font-weight: 400;
	width: 60%;
	text-align: center;
	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		font-size: 30px;
	}
	@media screen and (min-width: 1200px) {
		font-size: 40px;
	}
`

export const DesktopSignOutButton = styled(Button)`
	background-color: #fff;
	color: #000;
	cursor: pointer;
	font-weight: 400;
	outline: none;
	transition: background-color 0.2s ease;
	:hover {
		background-color: #f2f2f2;
	}
	img {
		margin-right: 10px;
	}
	@media screen and (max-width: 1024px) {
		display: none;
	}
	@media screen and (min-width: 1024px) {
		display: flex;
	}
`

export const MobileSignOutButton = styled(Button)`
	background-color: #fff;
	color: #000;
	cursor: pointer;
	font-weight: 400;
	outline: none;
	transition: background-color 0.2s ease;
	:hover {
		background-color: #f2f2f2;
	}
	@media screen and (max-width: 1024px) {
		display: flex;
	}
	@media screen and (min-width: 1024px) {
		display: none;
	}
`

export const Img = styled.img`
	width: 20px;
`
