import {includes} from 'lodash'
import React from 'react'

import {DocName, DocTitleSection, LeftIcon} from './styles'
import FileIcon from '../../assets/icons/file.svg'
import ImageIcon from '../../assets/icons/image.svg'
import VideoIcon from '../../assets/icons/video.svg'
import LinkIcon from '../../assets/icons/link.svg'

export default (fileType, fileName, docFormat, imageFormat, videoFormat) => {
	return (
		<DocTitleSection>
			{includes(docFormat, fileType) && (
				<LeftIcon src={FileIcon} alt='icon' />
			)}
			{includes(imageFormat, fileType) && (
				<LeftIcon src={ImageIcon} alt='icon' />
			)}
			{includes(videoFormat, fileType) && (
				<LeftIcon src={VideoIcon} alt='icon' />
			)}
			{fileType === 'link' && <LeftIcon src={LinkIcon} alt='icon' />}
			{fileType === 'video' && <LeftIcon src={VideoIcon} alt='icon' />}
			<DocName>{fileName}</DocName>
		</DocTitleSection>
	)
}
