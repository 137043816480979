import React from 'react'

import {
	Button,
	Container,
	ContentContainer,
	Img,
	Subtitle,
	Title,
} from './styles'
import JoanneLogo from '../../assets/images/logo-joanne.png'

export default () => (
	<Container>
		<Img src={JoanneLogo} />
		<ContentContainer>
			<Title>Oups !</Title>
			<Subtitle>It looks like you're lost...</Subtitle>
			<a href='/'>
				<Button>Back Home</Button>
			</a>
		</ContentContainer>
	</Container>
)
