import Auth from '@aws-amplify/auth'
import React, {useCallback, useEffect, useState} from 'react'

import {
	Button,
	ChangeFormButton,
	FooterContainer,
	FormHeader,
	Hint,
	Input,
	InputLabel,
	FormContainer,
} from '../styles'

const SignInForm = ({handleUser, handleDisplayedForm, handleErrors}) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleSignIn = useCallback(() => {
		Auth.signIn(email, password)
			.then(user => {
				if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					handleUser(user)
					handleDisplayedForm('change')
					handleErrors(null)
				} else {
					handleErrors(null)
					window.location.reload()
				}
			})
			.catch(error => handleErrors(error.message))
	}, [email, password, handleDisplayedForm, handleErrors, handleUser])

	useEffect(() => {
		const handleUserKeyPress = e => {
			if (e.keyCode === 13) {
				handleSignIn()
			}
		}
		window.addEventListener('keypress', handleUserKeyPress)
		return () => {
			window.removeEventListener('keypress', handleUserKeyPress)
		}
	}, [handleSignIn])

	return (
		<FormContainer>
			<FormHeader>Sign in to your account</FormHeader>
			<InputLabel>Email</InputLabel>
			<Input
				type='email'
				placeholder='Enter your Email'
				onChange={e => setEmail(e.target.value)}
			/>
			<InputLabel>Password</InputLabel>
			<Input
				type='password'
				placeholder='Enter your Password'
				onChange={e => setPassword(e.target.value)}
			/>
			<Hint>
				Forget your password ?{' '}
				<ChangeFormButton
					onClick={() => {
						handleErrors(null)
						handleDisplayedForm('forgot')
					}}
				>
					Reset password
				</ChangeFormButton>
			</Hint>
			<FooterContainer btnonly='true'>
				<Button btnonly='true' onClick={handleSignIn}>
					LOGIN
				</Button>
			</FooterContainer>
		</FormContainer>
	)
}

export default SignInForm
