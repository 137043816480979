import {compact, concat, includes, map, replace, split} from 'lodash'
import {useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import Storage from '@aws-amplify/storage'
import Analytics from '@aws-amplify/analytics'

import {
	BackBtn,
	BannerDiv,
	Close,
	Container,
	Content,
	DocDiv,
	HeaderTitle,
	HeaderSection,
	Icon,
	Img,
	Separator,
	Video,
	ViewerContainer,
} from './styles'

import BackIcon from '../../assets/icons/back.svg'
import CloseIcon from '../../assets/icons/close.svg'

import getActionSection from './actionSection'
import getTitleSection from './titleSection'

export default () => {
	const [s3, sets3List] = useState([])
	const [allCastleDocs, setAllCastleDocs] = useState([])
	const [isVideoReading, setIsVideoReading] = useState(false)
	const [videoReadingUrl, setVideoReadingUrl] = useState(false)
	const [isImageViewing, setIsImageViewing] = useState(false)
	const [imageViewingUrl, setImageViewingUrl] = useState(false)
	const [bannerImage, setBannerImage] = useState(null)
	const castleName = useParams()
	const videoFormat = ['mp4', 'mov']
	const imageFormat = ['jpg', 'png', 'jpeg']
	const docFormat = ['pdf', 'docx']
	const file = concat(videoFormat, imageFormat, docFormat)

	// =========== get banner image if available ===============
	const getBannerUrl = async (castleName, castleFile) => {
		await Storage.get('new_releases/'  +`${castleName}/${castleFile}`)
			.then(result => setBannerImage(result))
			.catch(err => console.log(err))
	}

	// =========== get doc files url for read and download based on file extension ===============
	const getDocUrls = async (castleName, castleFile, fileName, fileExt) => {
		const params = {
			contentDisposition: 'attachment',
		}
		await Storage.get('new_releases/' +`${castleName}/${castleFile}`, params)
			.then(async downloadResult => {
				if (fileExt === 'docx') {
					setAllCastleDocs(allCastleDocs => [
						...allCastleDocs,
						{
							castleName,
							file: castleFile,
							fileName: replace(
								fileName,
								new RegExp('_', 'g'),
								' '
							),
							fileType: fileExt,
							fileUrl: null,
							downloadUrl: downloadResult,
						},
					])
				} else {
					await Storage.get('new_releases/' + `${castleName}/${castleFile}`)
						.then(result => {
							setAllCastleDocs(allCastleDocs => [
								...allCastleDocs,
								{
									castleName,
									file: castleFile,
									fileName: replace(
										fileName,
										new RegExp('_', 'g'),
										' '
									),
									fileType: fileExt,
									fileUrl: result,
									downloadUrl: downloadResult,
								},
							])
						})
						.catch(err => console.log(err))
				}
			})
			.catch(err => console.log(err))
	}

	// =================== get link to open from txt file ==================
	const getDocLinks = async (castleName, castleFile, fileName, fileExt) => {
		const result = await Storage.get('new_releases/' + `${castleName}/${castleFile}`)
			.then(function (result) {
				fetch(result)
					.then(resp => resp.text())
					.then(result => {
						setAllCastleDocs(allCastleDocs => [
							...allCastleDocs,
							{
								castleName,
								file: castleFile,
								fileName: replace(
									fileName,
									new RegExp('_', 'g'),
									' '
								),
								fileType: includes(fileExt, 'video')
									? 'video'
									: 'link',
								fileUrl: result,
							},
						])
					})
					.catch(err => {
						console.log(err)
					})
			})
			.catch(err => console.log(err))
		return result
	}

	const handleReadVideo = url => {
		setVideoReadingUrl(url)
		setIsVideoReading(true)
	}
	const handleOpenImage = url => {
		setImageViewingUrl(url)
		setIsImageViewing(true)
	}

	// =================== Fetch all doc keys for the current Castle ==================
	useEffect(() => {
		const fetchS3 = async () => {
			Storage.list('new_releases/' + castleName.wine)
				.then(function (result) {
					sets3List(result)
					return result
				})
				.catch(err => console.log(err))
		}

		fetchS3()
	}, [castleName])

	// ================== Get all doc data ready to use based on key  =================
	useEffect(() => {
		const fetchAllCastleDocs = () => {
			compact(
				map(s3, doc => {
					console.log(doc.key)
					let path = doc.key.slice(13)
					const castleDocs = compact(split(path, '/', 2))
					if (
						castleDocs.length > 1 &&
						castleDocs[0] === castleName.wine
					) {
						const ext = split(castleDocs[1], '.', 2)
						if (ext[0] === 'banner') {
							getBannerUrl(castleDocs[0], castleDocs[1])
						} else if (includes(file, ext[1])) {
							getDocUrls(
								castleDocs[0],
								castleDocs[1],
								ext[0],
								ext[1]
							)
						} else if (includes(ext[1], 'lien')) {
							getDocLinks(
								castleDocs[0],
								castleDocs[1],
								ext[0],
								ext[1]
							)
						}
					} else {
						return
					}
				})
			)
		}
		Analytics.record({
			name: 'Page Chateau',
			// Attribute values must be strings
			attributes: {chateau: castleName.wine},
		})
		fetchAllCastleDocs()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [s3, castleName])

	return (
		<Container>
			{/* ================== LISTE DES FICHIERS DISPONIBLES ================== */}
			<Content>
				<HeaderSection>
					<a href='/wines'>
						<BackBtn>
							<Icon src={BackIcon} />
							Back
						</BackBtn>
					</a>
					{bannerImage && <BannerDiv bgImg={bannerImage} />}
					<HeaderTitle>
						{replace(castleName.wine, '_', ' ').toUpperCase()}
					</HeaderTitle>
					<Separator />
				</HeaderSection>
				{map(allCastleDocs, (castleDoc, index) => {
					return (
						<DocDiv key={index}>
							{getTitleSection(
								castleDoc.fileType,
								castleDoc.fileName,
								docFormat,
								imageFormat,
								videoFormat
							)}
							{getActionSection(
								castleName,
								castleDoc.fileName,
								castleDoc.fileType,
								castleDoc.fileUrl,
								castleDoc.downloadUrl,
								handleOpenImage,
								handleReadVideo,
								imageFormat,
								videoFormat
							)}
						</DocDiv>
					)
				})}
			</Content>
			{/* ================== LECTEUR VIDEO ================== */}
			{isVideoReading && (
				<ViewerContainer>
					<Close
						src={CloseIcon}
						onClick={() => setIsVideoReading(false)}
					/>
					<Video controls autoplay>
						<source src={videoReadingUrl} type='video/mp4' />
					</Video>
				</ViewerContainer>
			)}
			{/* ================== LECTEUR IMAGES ================== */}
			{isImageViewing && (
				<ViewerContainer>
					<Close
						src={CloseIcon}
						onClick={() => setIsImageViewing(false)}
					/>
					<Img src={imageViewingUrl} alt='wine' />
				</ViewerContainer>
			)}
		</Container>
	)
}
