import React, {useState} from 'react'

import {Container, Img, Toast} from './styles'
import JoanneLogo from '../../assets/images/logo-joanne.png'

import SignInForm from './customSignIn'
import ForgotPasswordForm from './customForgot'
import ResetPasswordForm from './customReset'
import RequiredNewPasswordForm from './customChange'

export default () => {
	const [submitError, setSubmitError] = useState(null)
	const [resetEmail, setResetEmail] = useState(null)
	const [displayedForm, setDisplayedForm] = useState('signin')
	const [user, setUser] = useState(null)

	const handleErrors = error => {
		if (error === 'Username cannot be empty') {
			setSubmitError('Email is required')
		} else if (error === 'CUSTOM_AUTH is not enabled for the client.') {
			setSubmitError('Password is required')
		} else {
			setSubmitError(error)
		}
	}

	return (
		<Container>
			{submitError && <Toast>{submitError}</Toast>}
			<Img src={JoanneLogo} />
			{displayedForm === 'signin' && (
				<SignInForm
					handleUser={setUser}
					handleDisplayedForm={setDisplayedForm}
					handleErrors={handleErrors}
				/>
			)}
			{displayedForm === 'forgot' && (
				<ForgotPasswordForm
					handleResetMail={setResetEmail}
					handleDisplayedForm={setDisplayedForm}
					handleErrors={handleErrors}
				/>
			)}
			{displayedForm === 'reset' && (
				<ResetPasswordForm
					resetEmail={resetEmail}
					handleDisplayedForm={setDisplayedForm}
					handleErrors={handleErrors}
				/>
			)}
			{displayedForm === 'change' && (
				<RequiredNewPasswordForm
					user={user}
					handleDisplayedForm={setDisplayedForm}
					handleErrors={setSubmitError}
				/>
			)}
		</Container>
	)
}
