import {Button as EmotionButton} from '@rebass/emotion'
import styled from '@emotion/styled'

export const Container = styled('div')`
	min-height: 100vh;
	background-color: #fff;
	display: flex;
	justify-content: center;
	@media screen and (max-width: 768px) {
		padding: 10px;
	}
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		padding: 20px;
	}
	@media screen and (min-width: 1200px) {
		padding: 20px;
	}
`

export const Content = styled('div')`
	width: 100%;
	max-width: 800px;
`

export const HeaderSection = styled('div')`
	font-family: 'Raleway', sans-serif;
	width: 100%;
	margin-bottom: 50px;
`

export const HeaderTitle = styled.h2`
	color: #000;
	font-weight: 700;
	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		font-size: 30px;
	}
	@media screen and (min-width: 1200px) {
		font-size: 30px;
	}
`

export const Separator = styled.div`
	background-color: #b51717;
	height: 2px;
	width: 100%;
	margin-bottom: 30px;
`

export const CastleList = styled.div`
	width: 100%;
`

export const CastleDiv = styled.div`
	max-width: 100%;
	background-color: #f8f8f8;
	color: #000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	padding: 0 20px;
`

export const CastleName = styled.p``

export const Button = styled(EmotionButton)`
	font-weight: 400;
	display: flex;
	align-items: center;
	margin-left: 15px;
	background-color: #b51717;
	padding-top: 5px;
	padding-bottom: 5px;
	transition: background-color 0.2s ease;
	outline: none;
	cursor: pointer;
	:hover {
		background-color: #8e1212;
	}
`

export const MobileButton = styled(Button)`
	@media screen and (max-width: 768px) {
		display: flex;
	}
	@media screen and (min-width: 768px) {
		display: none;
	}
`
export const DesktopButton = styled(Button)`
	@media screen and (max-width: 768px) {
		display: none;
	}
	@media screen and (min-width: 768px) {
		display: flex;
	}
`

export const RightIcon = styled.img`
	width: 20px;
	@media screen and (max-width: 768px) {
		margin-left: 0px;
	}
	@media screen and (min-width: 768px) {
		margin-left: 10px;
	}
`
