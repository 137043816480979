import { compact, flatMap, map, split, uniq } from 'lodash'
import React, { useEffect, useState } from 'react'
import Storage from '@aws-amplify/storage'
import Analytics from '@aws-amplify/analytics'

import {
  CastleDiv,
  CastleList,
  CastleName,
  Container,
  Content,
  DesktopButton,
  HeaderSection,
  HeaderTitle,
  MobileButton,
  RightIcon,
  Separator,
} from './styles'
import AccessIcon from '../../assets/icons/access.svg'
import { BackBtn, Icon } from '../castleDetails/styles'
import BackIcon from '../../assets/icons/back.svg'

export default () => {
  const [s3, sets3List] = useState([])

  useEffect(() => {
    const fetchS3 = async () => {
      Analytics.record({ name: 'Liste Chateaux' })
      // sample maxKeys: Storage.list('', {level: 'public', maxKeys: 500, delimiter: '/'})
      Storage.list('new_releases/', { delimiter: '/' })
        .then(function (result) {
          sets3List(result)
          return result
        })
        .catch((err) => console.log(err))
    }

    fetchS3()
  }, [])

  const getCastleName = () => {
    return uniq(
      flatMap(
        map(s3, (doc) => {
          console.log(doc.key)
          let path = doc.key.slice(13)
          return compact(split(path, '/', 1))
        }),
      ),
    )
  }

  return (
    <Container>
      <Content>
        <HeaderSection>
          <a href="/">
            <BackBtn>
              <Icon src={BackIcon} />
              Back
            </BackBtn>
          </a>
          <HeaderTitle>WINES</HeaderTitle>
          <Separator />
        </HeaderSection>
        <CastleList>
          {map(getCastleName(), (castle, index) => (
            <CastleDiv key={index}>
              <CastleName>{castle}</CastleName>
              <a href={`/wine/${castle}`}>
                <DesktopButton>
                  Documents <RightIcon src={AccessIcon} />{' '}
                </DesktopButton>
                <MobileButton>
                  <RightIcon src={AccessIcon} />{' '}
                </MobileButton>
              </a>
            </CastleDiv>
          ))}
        </CastleList>
      </Content>
    </Container>
  )
}
