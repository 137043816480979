import { compact, flatMap, map, split, uniq } from 'lodash'
import React, { useEffect, useState } from 'react'
import Storage from '@aws-amplify/storage'
import Analytics from '@aws-amplify/analytics'

import {
  CastleDiv,
  CastleList,
  CastleName,
  Container,
  Content,
  DesktopButton,
  HeaderSection,
  HeaderTitle,
  MobileButton,
  RightIcon,
  HomeRightIcon,
  Separator,
  DesktopBlackButton,
  MobileBlackButton,
} from './styles'
import AccessIcon from '../../assets/icons/access.svg'
import styles from './index.module.css'

export default () => {
  return (
    <Container>
      <Content>
        <HeaderSection>
          {/*<HeaderTitle>CHATEAUX</HeaderTitle>*/}
          {/*<Separator />*/}
        </HeaderSection>
        <div className={styles.buttons}>
          <a href="wines">
            <DesktopBlackButton>
              New Releases <HomeRightIcon src={AccessIcon} />
            </DesktopBlackButton>
            <MobileBlackButton>
              New Releases <HomeRightIcon src={AccessIcon} />
            </MobileBlackButton>
          </a>
          <a href="chateaux">
            <DesktopBlackButton>
              Primeurs <HomeRightIcon src={AccessIcon} />
            </DesktopBlackButton>
            <MobileBlackButton>
              Primeurs <HomeRightIcon src={AccessIcon} />
            </MobileBlackButton>
          </a>
        </div>
      </Content>
    </Container>
  )
}
